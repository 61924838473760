var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center", "align-v": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("h3", [_vm._v("404 - Page Not Found")]),
              _vm._v(" Either "),
              _c(
                "b-link",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("go back")]
              ),
              _vm._v(" or "),
              _c("b-link", { attrs: { to: "/loggers" } }, [_vm._v("go home")]),
              _vm._v(". "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }