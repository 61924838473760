<script>
export default {
  name: 'NotFoundPage',
};
</script>

<template>
  <b-container>
    <b-row align-h='center' align-v='center'>
      <b-col cols=4>
        <h3>404 - Page Not Found</h3>
        Either <b-link @click='$router.go(-1)'>go back</b-link>
        or <b-link to='/loggers'>go home</b-link>.
      </b-col>
    </b-row>
  </b-container>
</template>
